@import url("https://use.typekit.net/voy8mrc.css");

.marvin-font {
  font-family: marvin-round, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.brandon-font {
  font-family: brandon-grotesque, sans-serif;
  font-weight: 400;
  font-style: normal;
}