.page-mask-in {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  animation: opacIn 0.8s;
}
@keyframes opacIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.page-mask-out {
  display: none;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.window-in-lg {
  position: relative;
  left: 25%;
  border: none;
  z-index: 1;
  animation: opacIn 0.8s;
}

.window-in-sm {
  position: relative;
  border: none;
  z-index: 1;
  animation: opacIn 0.8s;
}

.window-out-lg {
  display: none;
  position: relative;
  top: 25%;
  border: none;
  z-index: 1;
}

.window-out-sm {
  display: none;
  position: relative;
  border: none;
  z-index: 1;
}
